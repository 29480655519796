@tailwind base;
@tailwind components;
@tailwind utilities;

.drawer-toggle ~ .drawer-side > .drawer-overlay {
  background-color: rgba(0, 0, 0, 0.295);
}

h1,
h2 {
  @apply text-[36px] leading-[42px] lg:leading-[70px] lg:text-[56px] font-bold text-navy;
}

h3 {
  @apply text-[30px] leading-[35px] font-bold;
}

h4 {
  @apply text-[24px] leading-[28px] font-bold;
}

img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

@layer base {
  body {
    font-family: "Quicksand", sans-serif;
    font-weight: 300;
    color: theme("colors.denim");
    background-color: #efefef;
    overflow-x: hidden;
    width: 100vw;
    line-height: 20px;
  }

  :root {
    --navy: #096784;
    --denim: #2b2d3a;
    --orange: #ff8329;
    --green: #69e269;
    --pink: #fa3676;
    --cyan: #40d3c1;
  }

  .with-transition {
    @apply transition-all ease-in duration-200;
  }

  .app-drawer-links a {
    @apply p-0 mb-8 text-white/50;
  }

  :is(.app-drawer-links, .app-nav-desktop-links) .active {
    @apply text-white with-line-above;
  }

  .with-line-above {
    @apply relative
    before:absolute before:-top-0.5 before:left-0 before:w-7 before:h-0 before:border before:border-white
    before:rounded-xl;
  }

  .line-above-center {
    @apply before:left-1/2 before:-translate-x-1/2;
  }

  .line-above-start {
    @apply before:left-0 before:-translate-x-0;
  }

  .with-curved-bottom {
    clip-path: ellipse(600px 500px at 50% calc(100% - 500px));
  }

  .app-home-hero {
    background-image: url("assets/img/home/mobile/background-home-hero.png");
    background-size: 100% 100%;
  }

  .app-exciting-updates {
    background-image: url("assets/img/home/exciting-updtes.svg");
  }

  .app-forager-international-about {
    background-image: url("assets/img/about/about-forager-international.svg");
  }

  .app-forager-international-companies {
    background-image: url("assets/img/companies/companies.svg");
  }

  .app-forager-international-our-team {
    background-image: url("assets/img/our-team/our-team-hero-bg-mobile.svg");
  }

  .app-forager-international-working {
    background-image: url("assets/img/working/bg-working-at-forager-mobile.svg");
  }

  .app-forager-international-news {
    background-image: url("assets/img/news/bg-news.svg");
  }

  .app-our-team-hero {
    background-image: url("assets/img/our-team/our-team-hero-bg-mobile.svg");
  }

  .bg-page-fmg {
    background-image: url("assets/img/fmg/bg-forager-media-group.png");
  }

  .bg-pages-companies {
    background-image: url("assets/img/companies//bg-companies.png");
  }

  .bg-page-foregaer-education {
    background-color: #163240;
  }

  .bg-page-foregaer-financial {
    background-image: url("assets/img/forager-financial/bg-mobile.png");
  }

  .bg-page-mission-and-values {
    background-color: white;
  }

  .bg-cards-home {
    background-image: url("assets/img/home/bg-cards.png");
  }

  .bg-not-found-page {
    background-image: url("assets/img/404/bg-desktop.png");
  }
}

@screen md {
  .with-curved-bottom {
    clip-path: ellipse(3295px 3860px at 50% calc(100% - 3860px));
  }

  .app-home-hero {
    background-image: url("assets/img/home/desktop/bg-home-hero-desktop.png");
  }

  .app-forager-international-about {
    background-image: url("assets/img/about/bg-about-desktop.svg");
  }

  .app-forager-international-companies {
    background-image: url("assets/img/companies/companies-desktop.svg");
  }

  .app-forager-international-our-team {
    background-image: url("assets/img/our-team/our-team-hero-bg-desktop.svg");
  }

  .app-forager-international-news {
    background-image: url("assets/img/news/bg-news-desktop.svg");
  }

  .app-exciting-updates {
    background-image: url("assets/img/home/exciting-updtes-desktop.svg");
  }

  .app-our-team-hero {
    background-image: url("assets/img/our-team/our-team-hero-bg-desktop.svg");
  }

  .bg-cards-home {
    background-image: url("assets/img/home/bg-cards-desktop.png");
  }

  .bg-not-found-page {
    background-image: url("assets/img/404/bg-desktop.png");
  }
}

.menu-item .menu-item__line {
  background-color: transparent;
}
.menu-item:hover .menu-item__line {
  @apply bg-cyan;
}
.menu-item:hover .menu-item__link {
  @apply text-cyan;
}
.selected-menu-item .menu-item__line {
  @apply bg-cyan;
}
.selected-menu-item .menu-item__link {
  @apply text-cyan;
}

select::-ms-expand {
  display: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: "";
}

/* carousel styling starts */

.bg-arrow-carousel {
  background: radial-gradient(
    100% 50% at 0% 49.86%,
    theme("colors.denim") 0%,
    theme("colors.denim") 0.01%,
    rgba(26, 30, 41, 0) 99.99%,
    rgba(26, 30, 41, 0) 100%
  );
}

.drawer-toggle ~ .drawer-side > .drawer-overlay {
  background-color: rgba(0, 0, 0, 0.295);
}

.app-carousel::-webkit-scrollbar,
.no-scroll-bar::-webkit-scrollbar {
  display: none;
}

.app-carousel,
.no-scroll-bar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.app-carousel .app-carousel__band.move {
  animation: move-carousel linear infinite;
}

.bg-full {
  background-size: 100% 100%;
}

@keyframes move-carousel {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
/* carousel styling ends */

.sector-card .text {
  left: 20px;
  bottom: 5%;
}

.sector-card:hover .text {
  bottom: 75%;
}

.sector-card .text p {
  opacity: 0;
}

.sector-card:hover .text p {
  opacity: 100%;
}

.member-card:hover h4 {
  @apply text-cyan;
}

.member-card .visual {
  @apply transition-all ease-in duration-1000;  background-size: auto 100%;
}

.member-card:hover .visual {
  background-size: auto 105%;
}